<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div v-if="faqs">
      <b-modal
          id="modal-delete"
          cancelTitle="Cancel"
          centered
          ok-title="Delete"

          title="Delete FAQ"
          @ok="deleteFAQ"
      >
        <span>Are You Sure ?</span>
      </b-modal>
      <!-- Table Container Card -->
      <b-card
          class="mb-0"
      >
        <b-row>

          <b-col class="mb-1" cols="12" md="12">
            <h1>FAQS</h1>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group label="Search">
              <debouncer :placeHolder="`search by`" @setValue="getsearchValue"></debouncer>
            </b-form-group>
          </b-col>
          <b-col class="d-flex flex-column -start  mb-1 mb-md-0"
                 cols="12" md="3"
          >
            <b-form-group label="Count">
              <v-select
                  v-model="perPage"
                  :clearable="false"
                  :options="perPageOptions"
                  class="per-page-selector  "
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="faqs"
            bordered
            class="position-relative"
            empty-text="Nothing found!"
            primary-key="id"
            responsive
            show-empty
            striped
        >
          <template #cell(edit)="data">
            <b-link :to="`/apps/site-setting/faqs/update/${data.item.id}`" class="cursor-pointer">
              <feather-icon class="text-primary" icon="EyeIcon" size="20"/>
            </b-link>
          </template>

          <template #cell(delete)="data">
            <span v-b-modal.modal-delete class="cursor-pointer" @click="setSelectedFAQ(data.item)">
              <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
            </span>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
            >
              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import { BCard, BCol, BFormGroup, BLink, BModal, BOverlay, BPagination, BRow, BTable } from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { DeleteFAQ, GetAllFAQ } from '@/libs/Api/Faq'
import Debouncer from '@/views/components/debouncer.vue'


export default {
  title: 'manage faqs',
  name: 'manage-faqs',
  data() {
    return {
      overlay: false,
      faqs: null,
      totalCount: null,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'title',
          label: 'title'
        },
        {
          key: 'edit',
          label: 'edit'
        },
        {
          key: 'delete',
          label: 'delete'
        },
      ],
      pageNumber: 1,
      search: '',
      selectedFAQ: null,
    }
  },
  async created() {
    await this.getAllfaqs()
  },
  watch: {
    search: function () {
      this.getAllfaqs()
    },
    currentPage: function () {
      this.getAllfaqs()
    },
    perPage:function (){
      this.getAllfaqs()
    }

  },
  methods: {
    getsearchValue(data){
      this.search = data
      this.getAllfaqs()
    },
    setSelectedFAQ(item) {
      this.selectedFAQ = JSON.parse(JSON.stringify(item))
    },
    async getAllfaqs() {
      let _this = this
      _this.overlay = true

      let data = {
        pageNumber: _this.currentPage,
        count: _this.perPage,
        search:_this.search
      }

      let getAllFAQ = new GetAllFAQ(_this)
      getAllFAQ.setParams(data)
      await getAllFAQ.fetch(function (content) {
        _this.overlay = false
        _this.faqs = content.data.data
        _this.totalCount = content.data.totalCount
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },
    async deleteFAQ() {
      let _this = this
      _this.overlay = true

      let data = {
        id: _this.selectedFAQ.id
      }
      let deleteFAQ = new DeleteFAQ(_this)
      deleteFAQ.setParams(data)
      await deleteFAQ.fetch(function (content) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Done`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.getAllfaqs()
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },

  },
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    BOverlay,
    BFormGroup,
    BModal,
    vSelect,
    Debouncer
  },
}
</script>

<style scoped>

</style>
