import WebServiceRequest from './WebServiceRequest'

class CreateFAQ extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Faqs/CreateFaqs')
  }
  setParams(data){
    super.setRequestParamDataObj(data)
  }
}

class UpdateFAQ extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('Faqs/UpdateFaqs')
  }
  setParams(data){
    super.setRequestParam(data)
  }
  setPayload(data){
    super.setRequestParamDataObj(data)
  }
}

class GetFAQById extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Faqs/GetFaqById')
  }
  setParams(data){
    super.setRequestParam(data)
  }
}

class DeleteFAQ extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag('Faqs/DeleteFaqs')
  }
  setParams(data){
    super.setRequestParam(data)
  }
}

class GetAllFAQ extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Faqs/GetFaqs')
  }
  setParams(data){
    super.setRequestParam(data)
  }
}

export {
  CreateFAQ,
  UpdateFAQ,
  GetFAQById,
  DeleteFAQ,
  GetAllFAQ,
}
